
import { Vue, Options } from "vue-class-component";
import PortfolioModal from "./Modals/PortfolioModal.vue";
import portfolioItemsData from "@/assets/data/portfolio.json";
import store from "@/store";
import PortfolioModule from "@/app/modules/portfolio/PortfolioModule";

@Options({
  components: {
    PortfolioModal,
  },
})
export default class Portfolio extends Vue {
  private modalItem: object = {};
  private numberOfItemsToDisplay: number = 9;
  private addNumberOfItemsByStep: number = 5;
  private portfolioItems: Array<object> = portfolioItemsData;
  private portfolioModule: PortfolioModule = new PortfolioModule();

  get portfolioItemsView() {
    return this.portfolioItems.slice(0, this.numberOfItemsToDisplay);
  }

  portfolioBoxClicked(id: number): void {
    this.modalItem = this.portfolioItems[id - 1];
    this.portfolioModule.initCarousel();
    this.portfolioModule.setActiveItem(0);
    store.commit("modal/openModal", "portfolio-modal");
  }

  showMoreItems(): void {
    this.numberOfItemsToDisplay += this.addNumberOfItemsByStep;
  }
}
