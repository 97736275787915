
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BaseModal from "@/components/Base/BaseModal.vue";

@Options({
  components: {
    BaseModal,
  },
})
export default class PortfolioModal extends Vue {
  @Prop({ type: Object, required: true })
  item: object = {};
}
