
import { Vue, Options } from "vue-class-component";
import store from "@/store";
import TestimonialModal from "./Modals/TestimonialModal.vue";
import commentsData from "@/assets/data/comments.json";
import clientsData from "@/assets/data/clients.json";
import OurClientsModule from "@/app/modules/ourClients/OurClientsModule";

@Options({
  components: {
    TestimonialModal,
  },
})
export default class OurClients extends Vue {
  private modalItem: object = {};
  private comments: Array<object> = commentsData;
  private clients: Array<object> = clientsData;
  private ourClientsModule: OurClientsModule = new OurClientsModule();

  created() {
    this.ourClientsModule.initCarousel();
  }

  openTestimonialModal(id: number): void {
    this.modalItem = this.comments[id];
    store.commit("modal/openModal", "testimonial-modal");
  }
}
