
import { Vue, Options } from "vue-class-component";
import ContactForm from "@/components/ContactForm.vue";
import Map from "@/components/Map.vue";

@Options({
  props: {
    msg: String,
  },
  components: {
    ContactForm,
    Map,
  },
})
export default class Footer extends Vue {}
