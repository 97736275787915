
import { Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";

export default class ContactForm extends Vue {
  private name: string = "";
  private email: string = "";
  private phone: string = "";
  private message: string = "";

  private messageSent: boolean = false;
  private messageError: boolean = false;
  private messageResponse: string = "";

  formNotFilled(): boolean {
    const fields = [this.name, this.email, this.phone, this.message];
    return (
      fields.filter((e) => {
        return e.length > 0 || typeof e == "undefined";
      }).length < fields.length
    );
  }

  sendEmail(): void {
    this.messageSent = false;
    this.messageError = false;

    axios
      .post("/backend/contact.php", {
        cname: this.name,
        cemail: this.email,
        cphone: this.phone,
        cmessage: this.message,
      })
      .then((response: AxiosResponse) => {
        if (response.data.status == 0) {
          this.messageError = true;
        }

        this.messageResponse = response.data.msg;
      })
      .catch((error: Error) => {
        this.messageError = true;
        this.messageResponse = error.message;
      })
      .finally(() => {
        this.messageSent = true;
      });
  }
}
