
import { Vue } from "vue-class-component";
import store from "@/store";
import { Prop } from "vue-property-decorator";

export default class BaseModal extends Vue {
  @Prop({ type: String, required: true })
  id: string = "";

  mounted() {
    const self = this;
    document.addEventListener("keydown", function (e) {
      if (e.key === "Escape") {
        store.commit("modal/closeModal", self.id);
      }
    });
  }

  closeModal(): void {
    store.commit("modal/closeModal", this.id);
  }

  isModalVisible(): boolean {
    return store.getters["modal/isVisible"](this.id);
  }
}
