
const state = {
    visibleModals: [],
}

const getters = {
    isVisible: (state: any) => (id: any) => {
        return state.visibleModals.includes(id)
    }
}

const actions = {

}

const mutations = {
    closeModal(state: any, id: string) {
        if (state.visibleModals.includes(id)) {
            state.visibleModals.pop(id)
        }
    },

    openModal(state: any, id: string) {
        if (!state.visibleModals.includes(id)) {
            state.visibleModals.push(id)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}