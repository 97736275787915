import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "nano-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")
  const _component_Intro = _resolveComponent("Intro")
  const _component_Portfolio = _resolveComponent("Portfolio")
  const _component_Design = _resolveComponent("Design")
  const _component_KeysToSuccess = _resolveComponent("KeysToSuccess")
  const _component_MobileFirst = _resolveComponent("MobileFirst")
  const _component_OurClients = _resolveComponent("OurClients")
  const _component_JobOffers = _resolveComponent("JobOffers")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Nav),
      _createVNode(_component_Intro),
      _createVNode(_component_Portfolio),
      _createVNode(_component_Design),
      _createVNode(_component_KeysToSuccess),
      _createVNode(_component_MobileFirst),
      _createVNode(_component_OurClients),
      _createVNode(_component_JobOffers),
      _createVNode(_component_Footer)
    ])
  ]))
}