export default class OurClientsModule {
    public initCarousel(): void {
        window.$(document).ready(function () {
            window.$("#owl-clients").owlCarousel({
                nav: true,
                dots: false,
                items: 3,
                slideBy: 3,
                navText: [
                    '<i class="icon-arrow-left2"></i>',
                    '<i class="icon-arrow-right2"></i>',
                ],
                responsive: {
                    0: {
                        items: 2,
                    },
                    480: {
                        items: 3,
                    },
                },
            });

            window.$("#owl-testimonials").owlCarousel({
                nav: false,
                dots: false,
                singleItem: true,
                items: 1,
                autoplay: true,
                loop: true,
                autoplayTimeout: 8000,
                autoplaySpeed: 100,
            });
        });
    }
}