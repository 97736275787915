
import { Vue, Options } from "vue-class-component";
import JobOfferModal from "./Modals/JobOfferModal.vue";
import jobOffersData from "@/assets/data/jobOffers.json";
import store from "@/store";

@Options({
  components: {
    JobOfferModal,
  },
})
export default class JobOffers extends Vue {
  mailto: string = "jobs@mobilem.net";
  private modalItem: object = {};
  private jobOffers: Array<object> = jobOffersData;

  jobClicked(id: number): void {
    this.modalItem = this.jobOffers[id - 1];
    store.commit("modal/openModal", "joboffer-modal");
  }

  emailClicked(): void {
    window.open(`mailto:${this.mailto}`, "_blank");
  }
}
