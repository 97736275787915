
import { Options, Vue } from "vue-class-component";
import Nav from "@/components/Nav.vue";
import Intro from "@/components/Intro.vue";
import Portfolio from "@/components/Portfolio.vue";
import Design from "@/components/Design.vue";
import KeysToSuccess from "@/components/KeysToSuccess.vue";
import MobileFirst from "@/components/MobileFirst.vue";
import OurClients from "@/components/OurClients.vue";
import JobOffers from "@/components/JobOffers.vue";
import Footer from "@/components/Footer.vue";

@Options({
  props: {
    msg: String,
  },
  components: {
    Nav,
    Intro,
    Portfolio,
    Design,
    KeysToSuccess,
    MobileFirst,
    OurClients,
    JobOffers,
    Footer,
  },
})
export default class MainPage extends Vue {}
