
import { Vue } from "vue-class-component";

export default class Nav extends Vue {
  private isHamburgerMenuOn: boolean = false;

  toggleMenu(): void {
    this.isHamburgerMenuOn = !this.isHamburgerMenuOn;
  }

  closeMenu(): void {
    this.isHamburgerMenuOn = false;
  }
}
