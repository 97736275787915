import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/main.scss'
import 'bootstrap'
import 'jquery'

declare global {
    interface Window {
        $: any;
        jQuery: any;
    }
}

window.$ = window.jQuery = require('jquery');
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

createApp(App).use(store).use(router).mount('#mobilem-website-app')