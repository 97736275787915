export default class PortfolioModule {
    public initCarousel(): void {
        window.$(document).ready(function () {
            window.$("#portfolio-carousel").owlCarousel({
                nav: true,
                dots: true,
                singleItem: true,
                items: 1,
                navText: [
                    '<i class="icon-arrow-left2"></i>',
                    '<i class="icon-arrow-right2"></i>',
                ],
            });

            window.$(".amazing-thumbs img").click(function (e: Event) {
                const el = window.$(e.currentTarget);
                const slide = el.data("thumb");
                window.$(".amazing-thumbs img").removeClass("active");
                window.$(e.target).addClass("active");
                window.$("#portfolio-carousel").trigger("to.owl.carousel", slide, true);
            });

            window
                .$("#portfolio-carousel")
                .on("initialized.owl.carousel resized.owl.carousel", function (e: any) {
                    window.$(".amazing-thumbs img").first().addClass("active");

                    if (e.item.count == 1) {
                        window
                            .$(".pimage-cell .owl-nav, .amazing-thumbs")
                            .addClass("hidden");
                    }
                    if (e.item.index == 0) {
                        window.$(".pimage-cell .owl-prev").addClass("hidden");
                    }
                    window
                        .$(e.target)
                        .toggleClass("hide-nav", e.item.count <= e.page.size);
                });

            window
                .$("#portfolio-carousel")
                .on("translated.owl.carousel", function (e: any) {
                    if (e.item.index == 0) {
                        window.$(".pimage-cell .owl-prev").addClass("hidden");
                    } else {
                        window.$(".pimage-cell .owl-prev").removeClass("hidden");
                    }
                    const current = e.item.index;
                    const maxItems = e.item.count - 1;

                    if (e.item.index == maxItems) {
                        window.$(".pimage-cell .owl-next").addClass("hidden");
                    } else {
                        window.$(".pimage-cell .owl-next").removeClass("hidden");
                    }
                    window.$(".amazing-thumbs img").removeClass("active");
                    window.$(".amazing-thumbs img").eq(current).addClass("active");
                });
        });
    }

    public setActiveItem(itemNumber: number): void {
        window.$("#portfolio-carousel").trigger("to.owl.carousel", [itemNumber, 1]);
    }
}