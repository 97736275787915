import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "port-image" }
const _hoisted_2 = { class: "pimage-table" }
const _hoisted_3 = { class: "pimage-cell" }
const _hoisted_4 = {
  class: "owl-carousel",
  id: "portfolio-carousel"
}
const _hoisted_5 = { class: "amazing-thumbs" }
const _hoisted_6 = { class: "port-data" }
const _hoisted_7 = { class: "all-colors" }
const _hoisted_8 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseModal = _resolveComponent("BaseModal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_BaseModal, { id: 'portfolio-modal' }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.images, (image, key) => {
                  return (_openBlock(), _createBlock("div", {
                    class: "item",
                    key: key
                  }, [
                    _createVNode("img", {
                      src: image,
                      alt: _ctx.item.title
                    }, null, 8, ["src", "alt"])
                  ]))
                }), 128))
              ]),
              _createVNode("div", _hoisted_5, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.images, (image, key) => {
                  return (_openBlock(), _createBlock("img", {
                    class: { active: key == 1 },
                    key: key,
                    src: image,
                    "data-thumb": key - 1,
                    alt: _ctx.item.title,
                    width: "100",
                    height: "100"
                  }, null, 10, ["src", "data-thumb", "alt"]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode("h2", null, _toDisplayString(_ctx.item.title), 1),
          _createVNode("a", {
            href: 'http://' + _ctx.item.url,
            target: "_blank"
          }, _toDisplayString(_ctx.item.url), 9, ["href"]),
          _createVNode("span", _hoisted_7, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.colors?.split(','), (color, key) => {
              return (_openBlock(), _createBlock("span", {
                key: key,
                style: 'background: #' + color
              }, null, 4))
            }), 128))
          ]),
          _createVNode("div", _hoisted_8, [
            _createVNode("div", {
              class: "challenge col-12 col-sm-12",
              innerHTML: _ctx.item.desc
            }, null, 8, ["innerHTML"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}